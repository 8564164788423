<template>
    <div class="tool">
        <div class="search">
            <div style="margin-right: 10px;">
                <span style="font-size: 0.8rem;">时间：</span>
                <el-date-picker v-model="searchForm.begintime" :type="dateType" :format="formateData" value-format='yyyy-MM-dd' placeholder="选择日期"></el-date-picker>
            </div>
            <div v-if="User.user_type!==5" style="margin-right: 10px;">
                <span style="font-size: 0.8rem;">车场：</span>
                <el-select v-model="searchForm.pk_id" style="width:150px" @change="parkChanged">
                    <el-option v-if="User.user_type===5" label="全部" :value="0"></el-option>
                    <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                </el-select>
            </div>
            <el-button style="margin-right: 10px;" icon="el-icon-search" type="primary" @click='Search'>查询</el-button>
            <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="统计汇总" :fetch="createExportData" :before-finish="finishDownload" worksheet="统计汇总">
                <el-button type="primary" class="el-icon-download"> 导出</el-button>
            </JsonExcel>
        </div>
        

        <el-menu style="margin-top: 10px;" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1">日报汇总</el-menu-item>
            <el-menu-item index="2">月报汇总</el-menu-item>
            <el-menu-item index="3">年报汇总</el-menu-item>
        </el-menu>
        <el-table :data="DataList" size="mini" ref='orderTable' stripe :highlight-current-row='true' max-height='calc(100vh - 200px)' height='calc(100vh - 200px)'>
            <el-table-column  align="center" label=" " prop="time"></el-table-column>
            <el-table-column v-for="item in cardDataList" :key="item.card_id"  align="center" :label="item.card_name">
                <el-table-column align="center" label="数量">
                    <template slot-scope="scope">
                        {{bindCardSum(scope.row.listCard,item.card_id).inoutTotal}}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="金额">
                    <template slot-scope="scope">
                        {{toDecimal2(bindCardSum(scope.row.listCard,item.card_id).card_charge)}}
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column  align="center" label="免费放行">
                <el-table-column align="center" prop="freecount" label="数量"></el-table-column>
                <el-table-column align="center" prop="freecharge" label="金额">
                    <template slot-scope="scope">
                        {{toDecimal2(scope.row.freecharge)}}
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column  align="center" label="合计">
                <el-table-column align="center" label="总车辆数" prop="totalInout"></el-table-column>
                <el-table-column align="center" prop="discharge" label="减免金额">
                    <template slot-scope="scope">
                        {{toDecimal2(scope.row.discharge)}}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="money" label="现金收费">
                    <template slot-scope="scope">
                        {{toDecimal2(scope.row.money)}}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="elcmoney" label="电子收费">
                    <template slot-scope="scope">
                        {{toDecimal2(scope.row.elcmoney)}}
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import util from '../../../util/util'
import JsonExcel from 'vue-json-excel'

export default{
  data:function(){
      return{
        dateType:'date',
        search_time:'',
        formateData:'yyyy-MM-dd',
        activeIndex:"1",

        searchForm:{
            begintime:'',
            pk_id:0
        },

        User:null,
        FiledName:[],
        ParkDataList:[],
        DataList:[],


        CardTypeDataList:[],
        
        


        exportDataStandard:{},
        exportData: [],
        exportFooter:''
      }
  },
  components:{
    JsonExcel
  },
  computed:{
    cardDataList(){
      if(this.User.user_type===5){
          return this.$store.state.CardDataList
        }
      else{
        return this.CardTypeDataList
      }
    },
    bindCardSum(){
        return function(listCard,cardId){
            let arr=listCard.filter(a=>a.card_id===cardId)
            if(arr.length>0){
                return arr[0]
            }
            else
                return []
        }
    },
    toDecimal2(){
        return function(num){
          if(num===null){num=0}
          return util.toDecimal2(num)
        }
    },
  },
  created(){
      this.User = util.JesToken()
      this.searchForm.begintime=new Date()
      this.getParkDataList()
  },
  methods:{
    getParkDataList(){
        if(this.User.user_type===5){
            if(this.$store.state.CardDataList.length===0){
              this.$store.dispatch('getCardDataList')
            }
            this.getDataList()
        }
        else{
            util.Get('parkmanage/getparkdatalist').then(res => {
                if (res.rpStatus === 10000) {
                    this.ParkDataList = res.list
                    if(res.list.length>0){
                        this.searchForm.pk_id=res.list[0].pk_id
                        this.getCardDataList()
                        this.getDataList()
                    }
                }
            })
        }
    },   
    getCardDataList(){
      util.Get('card/getcarddatalist?id='+this.searchForm.pk_id).then(res => {
          if (res.rpStatus === 10000) {
            this.CardTypeDataList = res.list
          }
      })
    }, 
    parkChanged(value){
        this.searchForm.pk_id=value
        this.getDataList()
    },

    handleSelect(index){
        let dt="date"
        let st=new Date()
        this.formateData='yyyy-MM-dd'
        if(index==="2"){
            dt="month"
            this.formateData='yyyy-MM'
        }
        if(index==="3"){
            dt="year"
            this.formateData='yyyy'
        }
        this.dateType=dt
        this.searchForm.begintime=st
        this.activeIndex=index
    },
    getDataList(){
        this.searchForm.time_type=parseInt(this.activeIndex)
        util.Get("logsum/getlogsumdatalist",this.searchForm).then(res=>{
            if(res.rpStatus===10000){
                this.DataList=res.list
            }
            else{
                this.$message.error(res.$message)
            }
        })
    },
    Search(){
        this.getDataList()
    },

     // 导出
     async createExportData () {
        let arr=JSON.parse(JSON.stringify(this.DataList))
        if(arr.length>0){
            arr.forEach(a=>{
                this.cardDataList.forEach(card=>{
                    var info= a.listCard.filter(item=>item.card_id===card.card_id)
                    a["card_id_"+card.card_id+"_totalInout"]=info[0].inoutTotal
                    a["card_id_"+card.card_id+"_card_charge"]=info[0].card_charge
                })
            })
        }

        this.exportDataStandard={}
        this.exportDataStandard.日期="time"

        this.cardDataList.forEach(element => {
            this.exportDataStandard[element.card_name+'数量']="card_id_"+element.card_id+"_totalInout"
            this.exportDataStandard[element.card_name+'金额']="card_id_"+element.card_id+"_card_charge"
        });

        this.exportDataStandard.免费放行数量="freecount"
        this.exportDataStandard.免费放行金额="freecharge"
        this.exportDataStandard.总车辆数="totalInout"
        this.exportDataStandard.减免金额="discharge"
        this.exportDataStandard.现金收费="money"
        this.exportDataStandard.电子收费="elcmoney"

        this.exportData=arr
        return this.exportData
    },
    finishDownload () {
        this.$message.success('导出数据成功')
        this.exportData=[]
    }
  }
}


</script>


<style scoped>
.tool{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
}
.search{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
</style>